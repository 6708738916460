export const disableMouseScroll = (e) => {
  if (e.deltaX || (e.shiftKey && e.deltaY)) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const isIPadOS = () => (
  window.navigator.platform === 'iPad'
  || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
);

export const optimizeImageSrc = (imageSrc) => {
  if (!imageSrc || typeof imageSrc !== 'string') return imageSrc;

  if (!imageSrc.includes('imgix')) return imageSrc;

  if (imageSrc.includes('imgix') && imageSrc.includes('?')) {
    return imageSrc;
  }

  return `${imageSrc}?q=75&auto=format,compress`;
};

export function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}
