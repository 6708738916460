'use client';

import styled from 'styled-components';

import {
  Heading,
  Text,
  Paragraph,
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
} from 'grommet';
import { deepMerge } from 'grommet/utils';

const ModelBullet = styled.ul`
  line-height: normal;
  margin: 0;
  padding-inline-start: 20px
`;

const heading = [1, 2, 3, 4].reduce((obj, level) => {
  const result = { ...obj };
  result[`h${level}`] = {
    component: Heading,
    props: { level, thin: true, margin: { vertical: 'medium' } },
  };
  return result;
}, {});

const defaultOverrides = {
  ...heading,
  p: { component: Paragraph, props: { color: 'dark-4', size: 'small' } },
  table: {
    component: Table,
    props: { style: { maxWidth: '100%', width: '100%', fontSize: '1rem' } },
  },
  thead: { component: TableHeader },
  tbody: { component: TableBody },
  tfoot: { component: TableFooter },
  tr: { component: TableRow },
  th: { component: TableCell, props: { pad: 'small' } },
  td: { component: TableCell, props: { pad: 'small' } },
  pre: { props: { style: { fontSize: '1rem' } } },
};

const modelOverrides = {
  pricing: {
    ul: { component: ModelBullet, props: { size: '1.1rem' } },
    span: { component: Text, props: { size: '1.1rem' } },
  },
  account: {
    ul: { component: ModelBullet, props: { size: '1.1rem', color: '#585858' } },
    span: { component: Text, props: { size: '1.1rem', color: '#585858' } },
    p: { component: Paragraph, props: { size: 'small', color: '#585858' } },
  },
};

export default function markdownOverrides(type) {
  return deepMerge({ ...defaultOverrides, ...(type ? modelOverrides[type] : null) });
}
