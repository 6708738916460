'use client';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Layer, Box } from 'grommet';

import { isIOS } from 'react-device-detect';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { isIPadOS } from '@/helpers/util';

const RadiusLayer = styled(Layer)`
  ${({ round }) => round && `border-radius: ${round}`};
`;

const AppLayer = ({
  children, contentFill = false, ...rest
}) => {
  const contentRef = React.useRef(null);
  React.useEffect(() => {
    if ((isIOS || isIPadOS()) && contentRef.current) {
      disableBodyScroll(contentRef.current);
    }

    return () => {
      if (isIOS || isIPadOS()) {
        clearAllBodyScrollLocks();
      }
      document.body.removeAttribute('style');
    };
  }, []);

  return (
    <RadiusLayer {...rest}>
      {children[0]}
      <Box flex overflow="auto" ref={contentRef} fill={contentFill}>
        {children[1]}
      </Box>
    </RadiusLayer>
  );
};

AppLayer.propTypes = {
  children: PropTypes.node.isRequired,
  contentFill: PropTypes.bool,
};

export default AppLayer;
