'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import Link from 'next/link';
import { Anchor } from 'grommet';

export const StyledAnchor = styled(Anchor)`
  &, &:hover { text-decoration: underline; }

  ${({ buttonHoverColor }) => buttonHoverColor && `
    &, &:hover {
      text-decoration: none;
      background-color: ${buttonHoverColor};
    }
  `}
`;

const AppAnchor = ({
  forwardRef = null,
  path = null,
  href = null,
  children = null,
  onClick = () => null,
  noDecoration = false,
  buttonHoverColor = null,
  ...rest
}) => {
  const pathname = usePathname();
  const active = (pathname === path) || (pathname === href);

  const AnchorComp = ((active && !noDecoration) ? StyledAnchor : Anchor);

  return (
    <Link href={path || href} passHref legacyBehavior>
      <AnchorComp
        ref={forwardRef}
        onClick={onClick}
        active={active}
        buttonHoverColor={buttonHoverColor}
        {...rest}
      >
        {children}
      </AnchorComp>
    </Link>
  );
};

AppAnchor.propTypes = {
  forwardRef: PropTypes.shape({ current: PropTypes.any }),
  children: PropTypes.node,
  className: PropTypes.string,
  path: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  noDecoration: PropTypes.bool,
  buttonHoverColor: PropTypes.string,
};

export default AppAnchor;
